import logo from './logo.svg';
import React from 'react';
   import './App.css';

   function App() {
     return (
       <div className="App">
         <header className="App-header">
           <h1>Electromack Store</h1>
         </header>
         <main style={{ height: '100vh', overflow: 'hidden' }}>
           <iframe
             src="https://script.google.com/macros/s/AKfycbzje0r2Oly39gVr7v8XeSKOQ5xXaoL42infEb98pBwhW8-Stc3Xyx28SwL9TA-vyCYI/exec"
             style={{
              width: '100%',
              height: '100%',
              border: 'none',
              overflow: 'hidden'
            }}
             title="Electromack Store App"
           ></iframe>
         </main>
       </div>
     );
   }

   export default App;